// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/fabio/code/coronado-roofing/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("/home/fabio/code/coronado-roofing/src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-admin-js": () => import("/home/fabio/code/coronado-roofing/src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-home-js": () => import("/home/fabio/code/coronado-roofing/src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("/home/fabio/code/coronado-roofing/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/home/fabio/code/coronado-roofing/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-pw-forget-js": () => import("/home/fabio/code/coronado-roofing/src/pages/pw-forget.js" /* webpackChunkName: "component---src-pages-pw-forget-js" */),
  "component---src-pages-signin-js": () => import("/home/fabio/code/coronado-roofing/src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("/home/fabio/code/coronado-roofing/src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/fabio/code/coronado-roofing/.cache/data.json")

